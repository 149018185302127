import payload_plugin_mQVtJobjW7OW1O10VZk96YEjqch3VxfxmGOt_mGcxFg from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.10.1_pinia@2.3.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import payload_plugin_ii1TQvGk4HtR5t9eWLCpmbsOPHxR61FQC51yw0qA3_A from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.2.0_firebase-functions@6.3.2_f_ltingbrdwjerweckytjajvtvfi/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_M38Y1gRrQDSi2QtMKGHBCr_5UkTOnfX0ihoZ4sYCIIs from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.5.1_eslint@8.57.1_rollup@3.29.5_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_iVygmzp02D_hHGYhQIdj_fXPiXf_UitPZfrUEL6iIjo from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.5.1_eslint@8.57.1_rollup@3.29.5_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NSTHQq_0O6Msc0DlhoQENOJVzQiGKELMrKvHxzF8r7s from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.5.1_eslint@8.57.1_rollup@3.29.5_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_UhAYNiIUJtzjyDyIYkHBr6JFAbrHk3IbRcpDH8gCmow from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.5.1_eslint@8.57.1_rollup@3.29.5_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ngKHIm1G88Dp2o_OyiII4s_g1vJCnpfEflMROcvYaCc from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.5.1_eslint@8.57.1_rollup@3.29.5_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_w6yNdunOTRxQX0tmnKqXrN6tE34IZZlM0mZq_MlPOAU from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.5.1_eslint@8.57.1_rollup@3.29.5_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_f7jstHwTWl41HPzka_CmNGstkyFyJnF0vpiN2c_C_mQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.5.1_eslint@8.57.1_rollup@3.29.5_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Wl9E__6fq48_qYV5ynL7IGZSP9Y3M1g6EfX9ESjUS_c from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.10.1_pinia@2.3.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_puscSp0B_DL4VXP7tCHxzH23L0Mr8G_3qeiI0kH_3dc from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.5.1_eslint@8.57.1_rollup@3.29.5_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_OtOZ6CGly_Vz5_PCGGLA9qHLz2Y5_d5czYAX7q_3Lug from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/pwa-icons-plugin.ts";
import pwa_client__GVQetRrAwnXIDVg5yxAGnwXf4_syKEnsMRMgH7iIco from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_vite@6.2.2_workbox-build@7.3.0_workbox-window@7.3.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_client_0ZVtAY0Q4ox5dK3_aApNe9qJ_YfIBJin9MVoSlL6BLs from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.2.0_firebase-functions@6.3.2_f_ltingbrdwjerweckytjajvtvfi/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_SihZNInqpa1PiqGp921qiIhvG5nRKrw_I5xMtOqdnC4 from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin__NsTyyXB_v600ojXacf8AhyIMLDwp2k62eFjLidDJdM from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/vuefire-plugin.mjs";
import firebase_naranse2NcGm1zUNyHsHfIH6zwFPb7aXyMxNwEUzFWw from "/vercel/path0/app/plugins/firebase.ts";
import honeybadger_zNRxBhHZQB4tS25fZS7uHGTojU22NHic2tV7QjTxVfQ from "/vercel/path0/app/plugins/honeybadger.ts";
import lottie_client_mVRYbh__zK5WP7ShQJeFZzneojdriSaRs5PJJ43ljhY from "/vercel/path0/app/plugins/lottie.client.ts";
import useEmitter_tVjSgPGrpnhB3I05wfr07XxtOVOI_PlESUyCtx2_vqI from "/vercel/path0/app/plugins/useEmitter.ts";
import veevalidate_components_w2EslXIMYW5qvDYhcWXJqRdrnUeTZD9QdrWK5rp9H_M from "/vercel/path0/app/plugins/veevalidate-components.ts";
import veevalidate_rules_5HW_pYrMYRFbPAX4Hq1w2intComleCZY0NjGSoPARsA from "/vercel/path0/app/plugins/veevalidate-rules.ts";
export default [
  payload_plugin_mQVtJobjW7OW1O10VZk96YEjqch3VxfxmGOt_mGcxFg,
  payload_plugin_ii1TQvGk4HtR5t9eWLCpmbsOPHxR61FQC51yw0qA3_A,
  revive_payload_client_M38Y1gRrQDSi2QtMKGHBCr_5UkTOnfX0ihoZ4sYCIIs,
  unhead_iVygmzp02D_hHGYhQIdj_fXPiXf_UitPZfrUEL6iIjo,
  router_NSTHQq_0O6Msc0DlhoQENOJVzQiGKELMrKvHxzF8r7s,
  payload_client_UhAYNiIUJtzjyDyIYkHBr6JFAbrHk3IbRcpDH8gCmow,
  navigation_repaint_client_ngKHIm1G88Dp2o_OyiII4s_g1vJCnpfEflMROcvYaCc,
  check_outdated_build_client_w6yNdunOTRxQX0tmnKqXrN6tE34IZZlM0mZq_MlPOAU,
  chunk_reload_client_f7jstHwTWl41HPzka_CmNGstkyFyJnF0vpiN2c_C_mQ,
  plugin_vue3_Wl9E__6fq48_qYV5ynL7IGZSP9Y3M1g6EfX9ESjUS_c,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_puscSp0B_DL4VXP7tCHxzH23L0Mr8G_3qeiI0kH_3dc,
  pwa_icons_plugin_OtOZ6CGly_Vz5_PCGGLA9qHLz2Y5_d5czYAX7q_3Lug,
  pwa_client__GVQetRrAwnXIDVg5yxAGnwXf4_syKEnsMRMgH7iIco,
  plugin_client_0ZVtAY0Q4ox5dK3_aApNe9qJ_YfIBJin9MVoSlL6BLs,
  vuefire_auth_plugin_client_SihZNInqpa1PiqGp921qiIhvG5nRKrw_I5xMtOqdnC4,
  vuefire_plugin__NsTyyXB_v600ojXacf8AhyIMLDwp2k62eFjLidDJdM,
  firebase_naranse2NcGm1zUNyHsHfIH6zwFPb7aXyMxNwEUzFWw,
  honeybadger_zNRxBhHZQB4tS25fZS7uHGTojU22NHic2tV7QjTxVfQ,
  lottie_client_mVRYbh__zK5WP7ShQJeFZzneojdriSaRs5PJJ43ljhY,
  useEmitter_tVjSgPGrpnhB3I05wfr07XxtOVOI_PlESUyCtx2_vqI,
  veevalidate_components_w2EslXIMYW5qvDYhcWXJqRdrnUeTZD9QdrWK5rp9H_M,
  veevalidate_rules_5HW_pYrMYRFbPAX4Hq1w2intComleCZY0NjGSoPARsA
]